html, #root{
    height: 100%;
}

.header {
    background-color: #040505;
    padding: 25px;
}

.footer {
    background-color: #040505;
    padding: 25px;
    font-size:14px;
    color:white;
}

main {
    padding-top:25px;
    padding-bottom:25px;
}

body {
    background-color: #96b33d;
    color: black;
    font-family: "UniversLTStdCn";
    font-size: 22px;
    height: 100%;
}

h1 {
    color: black;
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
    font-family: "BebasNeueBold";
    margin-bottom:15px;
}

h2 {
    color:white;
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
    font-family: "BebasNeueBold";
}

.green {
    color: #94b33c;
}

.black {
    color: black;
}

.headerLogo {
    max-width: 475px;
}

@media only screen and (max-width: 999px) {
    .headerLogo {
        height: 24px !important;
    }

    .header, .footer {
        padding: 12px;
    }
}

.btnPrimary {
    background-color: #333333;
    color: white;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 4px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
}

.btnPrimary:hover {
    opacity: 0.87;
    color:white;
}

.btnListItem {
    background-color: #f3f3f3;
    width: 100%;
    text-align: left;
    border: none;
    border-bottom: 1px solid #333333;
    font-size: 14px;
    padding: 10px;
}

.btnListItem:hover {
    color: #94b33c
}

#addressList {
    border-left: 1px solid #333333; 
    border-right: 1px solid #333333;
}

ul li {
    padding-top:20px;
}

ul li:first-child {
    padding-top: 0px !important;
}

#dvResults{
    margin-top:20px;
    color:black;
}

.spinner-border{
    width:25px;
    height:25px;
    color:white
}

.link {
    color: white;
}

.link:hover {
    color: #94b33c !important;
}

.close{
    background-color:white !important;
    border:none;
    border-top-right-radius: 4px;
    border-bottom-right-radius:4px;
}


#txtAddress:focus {
    outline: 0 !important;
    box-shadow: none;
}

.section {
    margin-bottom:50px;
}

input {
    padding: 10px; 
    line-height: 24px;
}

.inputDark {
    border: 1px solid black !important;
}

@font-face {
    font-family: "BebasNeueRegular";
    src: local("BebasNeueRegular"), url("./fonts/BebasNeueRegular.ttf") format("truetype");
    font-weight: bold;
}

.BebasNeueRegular {
    font-family: "BebasNeueRegular";
}

@font-face {
    font-family: "BebasNeueBold";
    src: local("BebasNeueBold"), url("./fonts/BebasNeueBold.ttf") format("truetype");
    font-weight: bold;
}

.BebasNeueBold {
    font-family: "BebasNeueBold";
}

@font-face {
    font-family: "UniversLTStdCn";
    src: local("UniversLTStdCn"), url("./fonts/UniversLTStdCn.ttf") format("truetype");
    font-weight: 400;
}

.UniversLTStdCn {
    font-family: "UniversLTStdCn";
}

